import { Box, Divider, Drawer, IconButton } from "@mui/material";
import { Icon } from "components/Icons";
import React from "react";
import ContactForm from "./ContactForm";
import { useContactDrawer } from "./useContactDrawer";
import { getFocus, useGet } from "state/jotai";
import { active_project } from "state/store.global";
import { _project } from "state/store.projects";
import { STATICS } from "api/api";
import { useBreak } from "hooks/useBreak";

const ContactDrawer = () => {
  const { isOpen, toggle, unit } = useContactDrawer();
  const isMobile = useBreak('md_dn');

  const { pin, pid } = useGet(active_project);
  const buttonsLabel = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? [];
  const contactData = getFocus(_project, 'ContactProperties')?.[pin]?.[pid] ?? [];
  const isRent = unit?.isRent();
// console.log(contactData,"shjsjjs")
  // const isRent = false;  // Example flag
  const contacts = [
    {
      "id": 1,
      "AddressLine1": "",
      "AddressLine2": "",
      "Email": "kpy@nybolig.dk",
      "ImageUrl": "footer_3.svg",
      "Name": "Karl Johan Thygesen",
      "Phone": "+45 20 84 80 83"
    },
    {
      "id": 2,
      "AddressLine1": "",
      "AddressLine2": "",
      "Email": "vgb@pilea.dk",
      "ImageUrl": "footer_4.png",
      "Name": "Vibeke Graversen Bangstoft",
      "Phone": "+45 44 12 59 81"
    },
    {
      "id": 3,
      "AddressLine1": "",
      "AddressLine2": "",
      "Email": "vgb@pilea.dk",
      "ImageUrl": "footer_4.png",
      "Name": "Vibeke Graversen Bangstoft",
      "Phone": "+45 44 12 59 81"
    },
    {
      "id": 4,
      "AddressLine1": "",
      "AddressLine2": "",
      "Email": "vgb@pilea.dk",
      "ImageUrl": "footer_4.png",
      "Name": "Vibeke Graversen Bangstoft",
      "Phone": "+45 44 12 59 81"
    },
    {
      "id": 5,
      "AddressLine1": "",
      "AddressLine2": "",
      "Email": "vgb@pilea.dk",
      "ImageUrl": "footer_4.png",
      "Name": "Vibeke Graversen Bangstoft",
      "Phone": "+45 44 12 59 81"
    },
    {
      "id": 6,
      "AddressLine1": "",
      "AddressLine2": "",
      "Email": "vgb@pilea.dk",
      "ImageUrl": "footer_4.png",
      "Name": "Vibeke Graversen Bangstoft",
      "Phone": "+45 44 12 59 81"
    }
  ];
  
  // Determine which contact to show based on the `isRent` flag
  const contactsToShow = isRent 
    ? contactData.filter(contact => contact.Id >= 4 && contact.Id <= 6)  // Show objects 4, 5, 6 if `isRent` is true
    : contactData.filter(contact => contact.Id >= 1 && contact.Id <= 3); // Show objects 1, 2, 3 if `isRent` is false

    
  return (
    <Drawer
      anchor={isMobile ? "bottom" : "right"}
      open={isOpen}
      onClose={() => toggle()}
      PaperProps={{
        sx: {
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": { display: "none" },
          color: "secondary.contastText",
          backgroundColor: "secondary.main",
          height: "100%",
        },
      }}
    >
      <Box sx={{ width: isMobile ? "auto" : 422, position: "relative", pt: 4, pb: 4, px: 3, lineHeight: 1.3 }}>
        <IconButton
          onClick={toggle}
          sx={{
            position: "absolute",
            right: 16,
            top: 16,
          }}
        >
          <Icon
            icon="close"
            sx={{
              color: clr?.sub_text_color,
              fontSize: 18,
            }}
          />
        </IconButton>

        <Box sx={{ mt: 3, lineHeight: 1.5 }}>
          <Box sx={{ fontWeight: 700, mb: 1, color: clr?.sub_text_color }}>
            {buttonsLabel?.find(btn => btn?.UILabelProperty === 'Kontaktinformation')?.Label}
          </Box>

          {contactsToShow.slice(0, 1).map(contact => (
            <Box 
              key={contact.id} 
              sx={{ 
                display: 'flex', 
                flexDirection: isMobile ? 'column' : 'row', 
                alignItems: isMobile ? 'flex-start' : 'center', 
                gap: 2, 
                mb: 3 // Add margin between contacts
              }}
            >
              {/* {console.log(contactsToShow,"clllll")} */}
              <Box sx={{ flex: 1, mb: isMobile ? 2 : 0 }}>
                <Box>{contact.Name}</Box>
                {contact.AddressLine1 && <Box>{contact.AddressLine1}</Box>}
                {contact.AddressLine2 && <Box>{contact.AddressLine2}</Box>}
                <Box>{contact.Phone}</Box>
                <Box>{contact.Email}</Box>
              </Box>

              <Box 
                sx={{ 
                  width: '120px', 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: isMobile ? 'flex-start' : 'center', 
                }}
              >
                {contact.ImageUrl && (
                  <img
                    src={`${STATICS}${contact.ImageUrl}`}
                    alt="footer_logo"
                    style={{ width: '100%', height: 'auto' }} 
                  />
                )}
              </Box>
            </Box>
          ))}

        </Box>

        <Divider sx={{ my: 4, borderColor: "#000000" }} />

        {/* ContactForm can be rendered here as needed */}
      </Box>
    </Drawer>
  );
};

export default ContactDrawer;
