import { Navigate } from 'react-router-dom'

import { ViewIsometry }  from 'pages/View.Isometry'
import { ViewGallery }   from 'pages/View.Gallery'
import { ViewDownloads } from 'pages/View.Downloads'
import { ViewList }      from 'pages/View.List'
import { ViewCompare }   from 'pages/View.Compare'
import { ViewMap }       from 'pages/View.Map'
import { ViewFrontpage } from 'pages/View.Frontpage'
import { ViewFavorites } from 'pages/View.Favorites'
import { ViewUnit }      from 'pages/View.Unit'
import { ViewHelp }      from './View.Help'
import { IconGrid }      from 'components/IconGrid/IconGrid'
import ViewContact from './View.Contact'

export const multi_collective = (language,start) => [
  { path:`:${language}/`          , element:<Navigate to={start} replace/>, index:true },
  { path:`:${language}/airphoto`  , element:<ViewMap/>       },
  // { path:`:${language}/isometry`  , element:<ViewIsometry/>  },
  { path:`:${language}/compare`   , element:<ViewCompare/>   },
  { path:`:${language}/gallery`   , element:<ViewGallery/>   },
  { path:`:${language}/downloads` , element:<ViewDownloads/> },
  { path:`:${language}/list`      , element:<ViewList/>      },
  { path:`:${language}/favorites` , element:<ViewFavorites/> },
  { path:`:${language}/help`      , element:<ViewHelp/>      },
]
export const multi_single = (language,start) => [
  { path:`:${language}`              , element:<Navigate to={start} replace/>, index:true },
  { path:`:${language}/isometry`     , element:<ViewIsometry/>  },
  { path:`:${language}/isometry/:sub`, element:<ViewIsometry/>  },
  { path:`:${language}/isometry/:sub/list`, element:<ViewList/>  },
  { path:`:${language}/gallery`      , element:<ViewGallery/>   },
  { path:`:${language}/downloads`    , element:<ViewDownloads/> },
  { path:`:${language}/list`         , element:<ViewList/>      }, 
  { path:`:${language}/unit/:UnitID` , element:<ViewUnit/>      },
  { path:`:${language}/compare`      , element:<ViewCompare/>   },
  { path:`:${language}/favorites` , element:<ViewFavorites/> },
]
export const standard = (language,start) => [
  { path:`:${language}`              , element:<Navigate to={start} replace/>, index:true },
  { path:`:${language}/frontpage`    , element:<ViewFrontpage/> },
  { path:`:${language}/isometry`     , element:<ViewIsometry/>  },
  { path:`:${language}/isometry/:sub`, element:<ViewIsometry/>  },
  { path:`:${language}/gallery`      , element:<ViewGallery/>   },
  { path:`:${language}/downloads`    , element:<ViewDownloads/> },
  { path:`:${language}/list`         , element:<ViewList/>      }, 
  { path:`:${language}/compare`      , element:<ViewCompare/>   },
  { path:`:${language}/favorites`    , element:<ViewFavorites/> },
  { path:`:${language}/unit/:UnitID` , element:<ViewUnit/>      },
  { path:`:${language}/help`         , element:<ViewHelp/>      },
  { path:`:${language}/icons`        , element:<IconGrid/>      },
  { path:`:${language}/contact`        , element:<ViewContact/>      }
]