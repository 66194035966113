
import { useBreak } from "hooks/useBreak"
import { Label } from "./Overview.label"
import { _bootstrap } from "state/store.bootstrap"
import { getFocus, useAtom, useGet } from "state/jotai"
import { current_language, rotate_by_slider } from "state/store.global"
import { AnimatePresence } from "framer-motion"
import { motion } from 'framer-motion'
import { MobileLabel } from "./MobileOverview.label"
import { _project } from "state/store.projects"

export const Overview = ({ index, rmode, status }) => {

    const labelOptions    = getFocus(_bootstrap, 'projectnames')
    const currentLanguage = useAtom(current_language)[0];
    const isRotate_Done = useGet(rotate_by_slider)
    const isDesktop = useBreak('md_up')

    //L1[0] is left & right & L1[1]is top & down! -> north
    //L2[0] is left & right & L1[1]is top & down! -> south
    const L = [ // desktop labels position
    { L1:[1220, 300], L2:[100, 700]},/* 1 view */ 
    { L1:[800, 691], L2:[0, 289]},/* 2 view */ 
    { L1:[15, 200], L2:[1500, 600]},/* 3 view */ 
    { L1:[700, 195], L2:[900, 800]},/* 4 view */ 
    { L1:[1220, 300], L2:[100, 700]},/* 5 view */ 
    ];

    const circle = [        // desktop circle position
        { L1:[-400, 300], L2:[900, -200]},
        { L1:[-400, -57], L2:[500, 600]},
        { L1:[900, 448], L2:[-600, -102]},
        { L1:[0, 510], L2:[-138, -292]},
        { L1:[-400, 300], L2:[900, -200]},
    ]

    //L1[0] is left & right & L1[1]is top & down! -> north
    //L2[0] is left & right & L1[1]is top & down! -> south
    const mobileL = [   // circle position 
        { L1:[1055, 450], L2:[ 500, 562]},/* 1 view */ 
        { L1:[568, 655], L2:[257, 597]},/* 2 view */ 
        { L1:[467, 423], L2:[1188, 554]},/* 3 view */ 
        { L1:[650, 465], L2:[863, 644]},/* 4 view */ 
        { L1:[1055, 450], L2:[ 500, 562]},/* 5 view */ 
        ];

    const mobileLine = [    // how much line shoule be big from both axis
        { L1:[ 500, 0 ], L2:[ -700, 0 ]},
        { L1:[ 900, 0 ], L2:[ 900, 0 ]},
        { L1:[ 500, 0 ], L2:[ 600, 0 ]},
        { L1:[ 500, 0 ], L2:[ 900, 0 ]},
        { L1:[ 500, 0 ], L2:[ -700, 0 ]},
        ];

    const mobileLineAngle = [       // labels line rotation from the pin point (circle)
        { L1: 285, L2: 78},
        { L1: 309, L2: 278},
        { L1: 285, L2: 266},
        { L1: 285, L2: 312},
        { L1: 285, L2: 78},
        ];

    const mobileLabelAxis = [       // labels position (i.e top & left)
        { L1:[ -300, 40 ], L2:[ -390, -90 ] },
        { L1:[ -417, 254 ], L2:[ -417, 15 ] },
        { L1:[ -300, 42 ], L2:[ -300, -45 ] },
        { L1:[ -300, 40 ], L2:[ -400, 270 ] },
        { L1:[ -300, 40 ], L2:[ -390, -90 ] },
        ];

    const animation = {
        initial: { opacity:0 },
        animate: { opacity:1 },
        exit:    { opacity:0 },
        transition: { duration:0.1, ease:'linear' },
      }

    return (
        <AnimatePresence>
        {
            status === 'DONE' && isRotate_Done &&
            <>
            {
                isDesktop ? 
                labelOptions?.[currentLanguage].map((label, i) => {
                    if(i) { // labels will genrate according to different properties
                        return (
                                <motion.div {...animation} key={i}>
                                    <Label c={L?.[index]?.[`L${i}`]} sc={1} d={40} l={0} r={circle?.[index]?.[`L${i}`]} {...{ rmode }} labelsInfo = {label} /> 
                                </motion.div>)
                    }
                })
                :
                labelOptions?.[currentLanguage].map((label, i) => {
                    if(i) { // labels will genrate according to different properties
                        return (
                                <motion.div {...animation} key={i}>
                                    <MobileLabel c={mobileL?.[index]?.[`L${i}`]} sc={1} d={40} l={0} r={circle?.[index]?.[`L${i}`]} {...{ rmode }} labelsInfo = {label} line={mobileLine?.[index]?.[`L${i}`]} angle={mobileLineAngle?.[index]?.[`L${i}`]} mobileLabelAxis={mobileLabelAxis?.[index]?.[`L${i}`]} /> 
                        </motion.div>)
                    }
                })
            }
            </>  
        }
        </AnimatePresence>
    )
}