import styled, {css} from 'styled-components'

export const Mark = (props, state) => (
  <StyledMark {...props}>
    <Fig clr={clr}/>
  </StyledMark>
)

const StyledMark = styled.div(
  () => css`
    ${'' /* height: 10px; */}
    ${'' /* width: 10px; */}
    ${'' /* background: white; */}
    ${'' /* border-radius:100%; */}
    ${'' /* position:relative; */}
    ${'' /* top:50%; */}
    ${'' /* left:50%; */}
    ${'' /* transform:translatey(-50%); */}
    ${'' /* border:1px solid white; */}
  `
)


const Fig = styled.div`
  width:10px;
  height:10px;
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  background:${({clr}) => clr?.sub_text_color };
  border-radius:100%;
`