import { BREAK_ } from 'constants';
import styled, { css, keyframes } from 'styled-components'

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
`;

export const Svg = styled.svg`
  // width: 100px;
  // height: 100px;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: visible;
  pointer-events:none;
`
export const Circle = styled.circle`
  fill: ${({ clr }) => clr.primary_text};;
  animation: ${pulseAnimation} 1s infinite;
`
export const CircleGroup = styled.g`
&:hover {
  animation: ${pulseAnimation} 1s infinite;
}
`

export const Group = styled.g``

export const Line = styled.line`
  stroke:#fff;
  stroke-width:3;
`
export const Path = styled.path`
  stroke: ${({ clr }) => clr.primary_text};
  stroke-width:2;
`
export const Pos = styled.div(({ c, s }) => css`
  position: absolute;
  left: ${c[0]}px;
  top: ${c[1]}px;
  width: 0px;
  height: 0px;
`)
export const Lab = styled.div(({ d, l, s }) => css`
  //padding: 5px 10px;
  // background: #232935de;
  background: ${({ clr }) => clr.primary_text};
  transform: ${`rotate(-${d}deg) translate(-50%,-50%)`};
  transform-origin: left top;
  color: #000;
  left: ${l}px;
  top: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
//   border-radius: ${25 * s}px;
  // padding: ${15 * s}px ${100 * s}px;
  padding: 15px 25px;
  justify-content: center;
  align-items: center;
  gap: 15px;
  flex-direction: column;
  cursor:pointer;
 
  pointer-events:auto;
  border: 3px solid #fff;
`)
export const Outer = styled.div(({ d, l, xAxis, yAxis }) => css`
  width: ${l * 2}px;
  height: ${l * 2}px;
  top: ${xAxis}px;
  left: ${yAxis}px;
  border: 1px solid transparent;
  border-radius: 50%;
  pointer-events:none;
  background:transparent;
  position: absolute;
  transform-origin: left top;
  transform: ${`rotate(${d}deg) translate(-50%, -50%)`};
`)
export const Text1 = styled.div`
  white-space:nowrap;
  font-size: 1.5rem;
  ${ BREAK_.md_dn } {
    font-size: 1.7rem;
  }
  // font-size: 1.125rem;
  font-weight: 600;
  letter-spacing:0.25px;
  color: #fff;
  pointer-events:none;
  text-transform: uppercase;
  // padding: 1rem;
`
export const Text2 = styled.div`
  white-space:nowrap;
  font-size: 1.3rem;
  ${ BREAK_.md_dn } {
    font-size: 1.2rem;
  }
  // font-size: 0.925rem;
  font-weight: 400;
  letter-spacing:0.5px;
  color: #fff;
  pointer-events:none;
  // padding: 1rem;
`